import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme, DaysTimeWindow } from 'src/app/types/users.types';
import { UtilitiesComponent } from 'src/app/utilities/utilities.component';

@Component({
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class DateTimeWindowChooserModalComponent extends UtilitiesComponent {
    @Input() theme: AppTheme;
    daysTime: DaysTimeWindow;
    @Input() set daysTimeWindow(value: DaysTimeWindow) {
        if (value) {
            this.daysTime = value;
            this.scheduleForm.patchValue({ tariffName: value.name || '' });
        }
    }
    @Input() fromTrigger: boolean;
    @Input() label: string = 'Nome fascia oraria';
    public contentClass: string;
    public scheduleForm: FormGroup; // Creiamo il form

    constructor(public modalController: ModalController, private fb: FormBuilder, private platformService: PlatformService) {
        super();
        this.scheduleForm = this.fb.group({
            tariffName: [this.daysTime?.name || ''] // Campo per il nome
        });

        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
    }
    onTariffNameChange(event: any) {
        this.scheduleForm.patchValue({ tariffName: event.detail.value });
        console.log('Nome tariffa modificato:', event.detail.value);
    }
    submitRequest = async (daysTimeWindow: DaysTimeWindow): Promise<void> => {
        const tariffName = this.scheduleForm.value.tariffName || `Fascia oraria#${daysTimeWindow}`;

        // Creiamo il nuovo oggetto con il nome incluso
        const newSchedule: DaysTimeWindow = {
            ...daysTimeWindow,
            name: tariffName
        };

        console.log('submitRequest - Lista aggiornata:', newSchedule);
        await this.modalController.dismiss(newSchedule);
    };
}
