<ion-grid [formGroup]="form" class="ion-no-margin ion-no-padding">
    <ion-row class="ion-text-start ion-no-margin ion-no-padding">
        <ion-col size="12">
            <ion-item
                mode="md"
                [ngClass]="{
                    error: form.get(controlName).hasError('number') && (form.get(controlName).touched || form.get(controlName).dirty),
                    'ion-input-color-lili': theme === 'lili',
                    'ion-input-color-dark': theme !== 'lili' && !form.get(controlName).hasError('number') && !form.get(controlName).value,
                    'white-border-lili': theme === 'lili' && !form.get(controlName).hasError('number') && form.get(controlName).value,
                    'white-border-dark': theme !== 'lili' && !form.get(controlName).hasError('number') && form.get(controlName).value
                }"
                [ngStyle]="{
                    '--border-color': form.get(controlName).hasError('number')
                        ? 'red'
                        : !form.get(controlName).value
                        ? 'initial'
                        : form.get(controlName).valid
                        ? 'success'
                        : 'white'
                }"
                [lines]="lines"
                detail="false"
                class="ion-no-margin ion-no-padding"
            >
                <ion-label position="floating">
                    <ion-text class="text-input-roboto-regular-16" [ngClass]="'title-card-' + theme + ' medium-' + theme">{{ label | translate }}</ion-text>
                </ion-label>
                <ion-input
                    min="min"
                    max="max"
                    mode="md"
                    [step]="step"
                    type="number"
                    class="text-input-roboto-regular-16"
                    fill="outline"
                    [formControlName]="controlName"
                    labelPlacement="floating"
                    placeholder="{{ placeholder | translate }}"
                    [clearInput]="clearInput"
                ></ion-input>
            </ion-item>
        </ion-col>
    </ion-row>
    <ion-row class="ion-text-start ion-no-margin ion-no-padding" style="margin-top: -13px">
        <ion-col size="12" class="ion-text-start ion-no-margin ion-no-padding">
            <sub class="card-subtitle-roboto-medium-11 success" *ngIf="form.get(controlName).valid && form.get(controlName).touched">
                <!-- Mostra solo se il campo è valido e è stato toccato -->
                {{ successMessage | translate }}
            </sub>

            <sub
                class="message-input-roboto-bold-11 grey"
                *ngIf="!form.get(controlName).hasError('email') && (!form.get(controlName).touched || !form.get(controlName).dirty)"
            >
                <!-- Mostra solo se il campo non è valido, è stato toccato e il suo valore è vuoto -->
                {{ informationMessage | translate }}
            </sub>

            <sub
                class="message-input-roboto-bold-11 red"
                *ngIf="
                    form.get(controlName).hasError('email') &&
                    ((form.get(controlName).touched && form.get(controlName).value.length !== 0) || form.get(controlName).dirty)
                "
            >
                <!-- Mostra solo se c'è un errore email e il campo è stato toccato o modificato -->
                {{ errorMessage | translate }}
            </sub>
        </ion-col>
    </ion-row>
</ion-grid>
