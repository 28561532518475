<div class="searchbar" [ngClass]="adaptWidth">
    <ion-searchbar
        [value]="value"
        #ionSearchbar
        [ngClass]="'searchbar-' + theme + ' ' + addMarginPadding"
        (ionChange)="onChangeSearchValue($event)"
        animated="false"
        [placeholder]="searchLabel | translate"
        [debounce]="debounce"
        show-clear-button="always"
        mode="ios"
    ></ion-searchbar>
</div>
