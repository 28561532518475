import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-input-checkbox',
    templateUrl: './item-input-checkbox.component.html',
    styleUrls: ['./item-input-checkbox.component.scss']
})
export class ItemSampleCheckboxComponent implements OnInit {
    @Input() labelPosition: string = 'floating';
    @Input() lines = 'full';
    @Input() label: string;
    @Input() disabled: boolean = false;
    @Input() contentClass: string;
    @Input() theme: AppTheme;
    @Input() checked$: boolean = false;
    @Input() parentName: string;

    @Output() elementChecked = new EventEmitter<any>(); // Evento per gestire la selezione dell'elemento

    constructor(
        private translate: TranslateService,
        public platformService: PlatformService,
        private navCtrl: NavController,
        private alertController: AlertController
    ) {}

    ngOnInit() {}
    checkedButton(event: Event) {
        console.log('event: ', event);

        const customEvent = event as CustomEvent;
        this.elementChecked.emit(customEvent?.detail?.checked);
    }

    showAlert = async ($event): Promise<void> => {
        const alert = await this.alertController.create({
            header: this.translate.instant('view.alerts.thermostat.change-view.header'),
            message:
                this.translate.instant('view.alerts.thermostat.change-view.subHeader') +
                ' ' +
                this.translate.instant('view.alerts.thermostat.change-parent.message') +
                ' ' +
                this.parentName,
            buttons: [
                {
                    text: this.translate.instant('button.close'),
                    role: 'cancel',
                    cssClass: 'ion-no-padding ion-no-margin',
                    handler: () => {}
                }
            ]
        });
        await alert.present();
    };
}
