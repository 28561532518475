import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';
import { BackendService } from './backend.service';
import { SocketioService } from './socketio.service';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { WebsocketMessage } from 'src/app/models/websocket.models';
import { AnomalyEntity } from 'src/app/models/anomalies/anomaly-units.models';

@Injectable({
    providedIn: 'root'
})
export class AnomaliesService {
    private anomaliesByUnitEntities: AnomalyEntity[] = [];
    public anomaliesByUnitEntitiesObserver: ReplaySubject<AnomalyEntity[]>;
    private anomaliesByUserEntities: AnomalyEntity[] = [];
    public anomaliesByUserEntitiesObserver: ReplaySubject<AnomalyEntity[]>;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private backendService: BackendService, private socketioService: SocketioService) {
        this.anomaliesByUnitEntitiesObserver = new ReplaySubject<AnomalyEntity[]>(1);
        this.anomaliesByUserEntitiesObserver = new ReplaySubject<AnomalyEntity[]>(1);

        //Ci mettiamo in ascolto dei messaggi Websocket per le anomalie indoor
        this.socketioService.getMessage(WebsocketEventName.insertAnomaly).subscribe((message: WebsocketMessage<any>) => {
            if (message) {
                this.onInsertAnomalyByUnitEvent(message.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi Websocket per le anomalie indoor
        this.socketioService.getMessage(WebsocketEventName.deleteAnomaly).subscribe((message: WebsocketMessage<any>) => {
            if (message) {
                this.onDeleteAnomalyByUnitEvent(message.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi Websocket per le anomalie outdoor
        this.socketioService.getMessage(WebsocketEventName.insertAnomalyByUser).subscribe((message: WebsocketMessage<any>) => {
            if (message) {
                this.onInsertAnomalyByUserEvent(message.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi Websocket per le anomalie outdoor
        this.socketioService.getMessage(WebsocketEventName.deleteAnomalyByUser).subscribe((message: WebsocketMessage<any>) => {
            if (message) {
                this.onDeleteAnomalyByUserEvent(message.payload);
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onInsertAnomalyByUnitEvent(payload: any) {
        //Cerchiamo nella lista delle anomalie
        const index = this.anomaliesByUnitEntities?.findIndex((event) => event.anomalyId + event.sourceId == payload.anomalyId + payload.sourceId);
        //Se il websocket porta una evento che non conosciamo...
        if (index === -1) {
            //Aggiorniamo la lista delle anomalie aggiungendo la nuova anomalia
            this.anomaliesByUnitEntities.unshift(payload);
            //Notifichiamo le nuove anomalie
            console.log('Emitting added anomalyUnitsEntities', this.anomaliesByUnitEntities);
            this.anomaliesByUnitEntitiesObserver.next(this.anomaliesByUnitEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onDeleteAnomalyByUnitEvent(payload: any) {
        //Cerchiamo nella lista delle anomalie
        const index = this.anomaliesByUnitEntities?.findIndex((event) => event.anomalyId + event.sourceId == payload.anomalyId + payload.sourceId);
        //Se il websocket porta una evento che già conosciamo...
        if (index !== -1) {
            //Cancelliamo l'anomalia non più attiva dalla lista
            this.anomaliesByUnitEntities.splice(index, 1);
            //Notifichiamo i nuovi dati dell'evento
            console.log('Emitting removed anomalyUnitsEntities', this.anomaliesByUnitEntities);
            this.anomaliesByUnitEntitiesObserver.next(this.anomaliesByUnitEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onInsertAnomalyByUserEvent(payload: any) {
        //Cerchiamo nella lista delle anomalie
        const index = this.anomaliesByUserEntities?.findIndex((event) => event.anomalyId + event.sourceId == payload.anomalyId + payload.sourceId);
        //Se il websocket porta una evento che non conosciamo...
        if (index === -1) {
            //Aggiorniamo la lista delle anomalie aggiungendo la nuova anomalia
            this.anomaliesByUserEntities.unshift(payload);
            //Notifichiamo le nuove anomalie
            console.log('Emitting added anomalyUsersEntities', this.anomaliesByUserEntities);
            this.anomaliesByUserEntitiesObserver.next(this.anomaliesByUserEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onDeleteAnomalyByUserEvent(payload: any) {
        //Cerchiamo nella lista delle anomalie
        const index = this.anomaliesByUserEntities?.findIndex((event) => event.anomalyId + event.sourceId == payload.anomalyId + payload.sourceId);
        //Se il websocket porta una evento che già conosciamo...
        if (index !== -1) {
            //Cancelliamo l'anomalia non più attiva dalla lista
            this.anomaliesByUserEntities.splice(index, 1);
            //Notifichiamo i nuovi dati dell'evento
            console.log('Emitting removed anomalyUsersEntities', this.anomaliesByUserEntities);
            this.anomaliesByUserEntitiesObserver.next(this.anomaliesByUserEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadAnomaliesByUnitList(unitId: string): Observable<AnomalyEntity[]> {
        return this.backendService.get<AnomalyEntity[]>(`/v2/anomalies/unit/by-unit`, { unitId }).pipe(
            map((anomalies: AnomalyEntity[]) => {
                //Aggiorniamo la lista delle anomalie
                this.anomaliesByUnitEntities = anomalies;
                //Notifichiamo i nuovi dati anomalie

                this.anomaliesByUnitEntitiesObserver.next(this.anomaliesByUnitEntities);
                return anomalies;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadAnomaliesByUserList(userId: string): Observable<AnomalyEntity[]> {
        return this.backendService.get<AnomalyEntity[]>(`/v2/anomalies/unit/by-user`, { userId }).pipe(
            map((anomalies: AnomalyEntity[]) => {
                //Aggiorniamo la lista delle anomalie
                this.anomaliesByUserEntities = anomalies;
                //Notifichiamo i nuovi dati anomalie
                // console.log('Emitting new anomalies by user...', this.anomaliesByUserEntities);
                this.anomaliesByUserEntitiesObserver.next(this.anomaliesByUserEntities);
                return anomalies;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public deleteAnomaly(unitId: string, anomalyId: string, sourceId: string, level: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/anomalies/unit/delete`, { unitId, anomalyId, sourceId, level }).pipe(
            map(() => {
                return;
            })
        );
    }
}
