import { ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpLoaderFactory, HttpLoaderFactoryModule } from './http-loader-factory.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UnitsService } from './services/units.service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ErrorsModule } from './components/error-dialog/errors.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { MenusModule } from './components/menus/menus.module';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { UsersService } from './services/users.service';
import { NgChartsModule } from 'ng2-charts';
// Importa i provider per la localizzazione
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en'; // Aggiungi altre localizzazioni se necessario
import { SpinnerComponent } from './components/shared/spinner/spinner.component';

// Registra le lingue
registerLocaleData(localeIt, 'it');
registerLocaleData(localeEn, 'en');

// Funzione per rilevare la lingua del browser
export function getBrowserLocale(): string {
    const browserLang = navigator.language || navigator['userLanguage']; // Recupera la lingua del browser
    return browserLang.startsWith('it') ? 'it' : 'en'; // Imposta italiano se la lingua è "it", altrimenti inglese
}
/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
const config: SocketIoConfig = { url: `${environment.backendUrl}`, options: { autoConnect: true } };
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error('Errore catturato globalmente:', error);
  }
}

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@NgModule({
    declarations: [AppComponent, SpinnerComponent],
    bootstrap: [AppComponent],

    imports: [
        BrowserModule,
        HttpLoaderFactoryModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        NgChartsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            //Il service worker è abilitato tranne che in dev
            enabled: environment.name !== 'dev',
            // Register the ServiceWorker as soon as the app is stable or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        LoggerModule.forRoot({
            // serverLoggingUrl: '/logs',
            timestampFormat: 'dd MMM yyyy HH:mm:ss',
            level: NgxLoggerLevel.DEBUG
            // serverLogLevel: NgxLoggerLevel.ERROR,
        }),
        SocketIoModule.forRoot(config),
        ErrorsModule,
        MenusModule,

        IonicModule.forRoot({
            platform: {
                desktop: (win) => {
                    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
                    return !isMobile;
                },
                mobile: (win) => {
                    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
                    return isMobile;
                }
            }
        }),
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LOCALE_ID, useFactory: getBrowserLocale }, // Usa la funzione per determinare la lingua
        UnitsService,
        UsersService,
        DatePipe
    ]
})
export class AppModule {}
