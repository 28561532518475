import { Injectable } from '@angular/core';
import { UserDisplayMode } from 'src/app/types/users.types';

export type MenuItem = {
    title: string;
    icon: string;
    url: string;
    color?: string;
};

export type MenuLeft = {
    outdoor: {
        adminItems: Array<MenuItem>;
        supportItems: Array<MenuItem>;
        customerItems: Array<MenuItem>;
    };
    indoor: {
        serviceItems: Array<MenuItem>;
        ownerItems: Array<MenuItem>;
        memberItems: Array<MenuItem>;
        guestItems: Array<MenuItem>;
    };
};

@Injectable({ providedIn: 'root' })
export class LeftMenuService {
    private customerItems: Array<MenuItem>;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor() {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public setDisplayMode(displayMode: UserDisplayMode): void {
        // console.log('Setting displayMode to:', displayMode);
        switch (displayMode) {
            case UserDisplayMode.card:
                this.customerItems = [
                    {
                        title: 'menu.title.units',
                        url: `/outdoor/units/list/card`,
                        icon: 'home'
                    }
                ];
                break;
            case UserDisplayMode.map:
                this.customerItems = [
                    {
                        title: 'menu.title.units',
                        url: `/outdoor/units/list/map`,
                        icon: 'home'
                    }
                ];
                break;
            case UserDisplayMode.row:
                this.customerItems = [
                    {
                        title: 'menu.title.units',
                        url: `/outdoor/units/list/row`,
                        icon: 'home'
                    }
                ];
                break;
            default:
                this.customerItems = [
                    {
                        title: 'menu.title.units',
                        url: `/outdoor/units/list/card`,
                        icon: 'home'
                    }
                ];
                break;
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    get menu(): MenuLeft {
        return {
            // I menu in modalità outdoor
            outdoor: {
                adminItems: [
                    {
                        title: 'menu.title.dashboard',
                        icon: 'tv',
                        url: '/outdoor/dashboard'
                    },
                    {
                        title: 'menu.title.users',
                        icon: 'people',
                        url: '/outdoor/users/search'
                    },
                    {
                        title: 'menu.title.units',
                        url: '/outdoor/units/search',
                        icon: 'home'
                    },
                    {
                        title: 'menu.title.servers',
                        url: '/outdoor/servers',
                        icon: 'server'
                    },
                    {
                        title: 'menu.title.database',
                        url: '/outdoor/database',
                        icon: 'save'
                    },
                    {
                        title: 'menu.title.tools',
                        url: '/outdoor/tools',
                        icon: 'build'
                    },
                    {
                        title: 'menu.title.technologies',
                        url: '/outdoor/technologies',
                        icon: 'analytics'
                    },
                    {
                        title: 'menu.title.families',
                        url: '/outdoor/families',
                        icon: 'bulb-outline'
                    },
                    {
                        title: 'menu.title.products',
                        url: '/outdoor/products',
                        icon: 'extension-puzzle-outline'
                    },
                    {
                        title: 'menu.title.payments',
                        url: '/outdoor/payments',
                        icon: 'card-outline'
                    }
                ],
                supportItems: [
                    {
                        title: 'menu.title.users',
                        icon: 'people',
                        url: '/outdoor/users/search'
                    },
                    {
                        title: 'menu.title.units',
                        url: '/outdoor/units/search',
                        icon: 'home'
                    },
                    {
                        title: 'menu.title.products',
                        url: '/outdoor/products',
                        icon: 'extension-puzzle-outline'
                    },
                    {
                        title: 'menu.title.payments',
                        url: '/outdoor/payments',
                        icon: 'card-outline'
                    }
                ],
                //Cambia in base alle preferenze dell'utente
                customerItems: this.customerItems
            },
            // I menu in modalità indoor
            indoor: {
                serviceItems: [
                    // {
                    //     title: 'menu.title.checklist',
                    //     url: '/indoor/checklist',
                    //     icon: 'checkmark-done-outline'
                    // },
                    {
                        title: 'menu.title.panel',
                        url: '/indoor/panel',
                        icon: 'tv-outline'
                    },
                    {
                        title: 'menu.title.technologies',
                        url: '/indoor/technologies',
                        icon: 'analytics-outline'
                    },
                    {
                        title: 'menu.title.rooms',
                        url: '/indoor/rooms',
                        icon: 'home-outline'
                    },
                    {
                        title: 'menu.title.devices',
                        url: '/indoor/devices',
                        icon: 'bulb-outline'
                    },
                    {
                        title: 'menu.title.scenarios',
                        url: '/indoor/scenarios',
                        icon: 'videocam-outline'
                    },
                    {
                        title: 'menu.title.views',
                        url: '/indoor/views',
                        icon: 'eye-outline'
                    },
                    {
                        title: 'menu.title.users',
                        url: '/indoor/users',
                        icon: 'people-outline'
                    }
                ],
                ownerItems: [
                    {
                        title: 'menu.title.panel',
                        url: '/indoor/panel',
                        icon: 'tv-outline'
                    },
                    {
                        title: 'menu.title.views',
                        url: '/indoor/views',
                        icon: 'eye-outline'
                    },
                    {
                        title: 'menu.title.scenarios',
                        url: '/indoor/scenarios',
                        icon: 'videocam-outline'
                    },
                    {
                        title: 'menu.title.users',
                        url: '/indoor/users',
                        icon: 'people-outline'
                    }
                ],
                memberItems: [
                    {
                        title: 'menu.title.panel',
                        url: '/indoor/panel',
                        icon: 'tv-outline'
                    },
                    {
                        title: 'menu.title.users',
                        url: '/indoor/users',
                        icon: 'people-outline'
                    }
                ],
                guestItems: [
                    {
                        title: 'menu.title.panel',
                        url: '/indoor/panel',
                        icon: 'tv-outline'
                    }
                ]
            }
        };
    }
}
