import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { AppTheme, Role } from 'src/app/types/users.types';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Subscription, firstValueFrom } from 'rxjs';

import { ViewChooserModalComponent } from './view-chooser-modal/view-chooser-modal.component';
import { ViewEntity } from 'src/app/models/views.models';
import { ViewsService } from 'src/app/services/views.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'view-chooser',
    templateUrl: './view-chooser.page.html',
    styleUrls: ['./view-chooser.page.scss']
})
export class ViewChooserPage implements OnInit {
    public showValue: string;
    public views: ViewEntity[];
    public viewsFilteredByRole: ViewEntity[] = [];
    private _role: Role;
    @Input() isEdit?: boolean;
    @Input() viewName?: string;
    @Input() detail: boolean = true;
    @Input() theme: AppTheme;
    @Input() set role(value: Role) {
        this.showValue = '';
        this._role = value;
    }
    @Input() set value(value: string) {
        if (value) {
            const format = this.views.find((f) => f.name === value);
            this.showValue = format.name;
        }
    }
    @Input() unitId: string;

    @Output() selected = new EventEmitter<string>();
    public subs: Subscription;

    constructor(
        private platformService: PlatformService,
        public modalController: ModalController,
        private viewsService: ViewsService,
        private rootFormGroup: FormGroupDirective
    ) {}

    async ngOnInit() {
        this.views = await firstValueFrom(this.viewsService.loadViewsList(this.unitId));
        if (this.isEdit) {
            this.showValue = this.viewName;
        }
    }
    filteredViewsFunction() {
        this.viewsFilteredByRole = this.views.filter((v) => v.role === this._role);
    }

    async chooseView(): Promise<void> {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        this.filteredViewsFunction();
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: ViewChooserModalComponent,
            componentProps: {
                theme: this.theme,
                views: this.viewsFilteredByRole,
                showValue: this.showValue
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();

        const res = await modal.onWillDismiss();
        if (res.data) {
            this.showValue = res.data.name;
            this.selected.emit(res.data);
        }
    }
}
