export enum Group {
    default = 'customer',
    customer = 'customer',
    administrator = 'administrator',
    support = 'support'
}

export enum GroupVisibility {
    administrator = 3,
    support = 2,
    customer = 1
}

export enum UserType {
    logged = 'logged',
    outdoor = 'outdoor'
}

export enum AppTheme {
    default = 'dark',
    lili = 'lili',
    dark = 'dark'
}

export enum Language {
    it = 'it-IT',
    en = 'en-GB'
    // de = 'de-DE',
    // fr = 'fr-FR',
    // es = 'es-ES'
}

export enum Role {
    service = 'service',
    owner = 'owner',
    member = 'member',
    guest = 'guest'
}

export enum UserDisplayMode {
    row = 'row',
    card = 'card',
    map = 'map'
}

export enum MeasurementSystem {
    // default = 'si',
    international = 'si',
    english = 'en'
    // french = 'fr',
    // american = 'american'
}

export enum FavoriteHand {
    default = 'left',
    left = 'left',
    right = 'right'
}

export enum TimeFormat {
    h12 = 'h12',
    h24 = 'h24'
}

export enum SignUp {
    lilitech = 'lilitech',
    google = 'google',
    facebook = 'facebook',
    apple = 'apple'
}

export type DaysTimeWindow = {
    name?: string;
    days: Array<boolean>;
    allday: boolean;
    start: string;
    end: string;
};
