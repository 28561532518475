import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { SocketioService } from './socketio.service';
import { HttpResponse } from '@angular/common/http';
import { BackendService } from './backend.service';
import { UnitByUserData, UnitByUserEntity, UnitData, UnitEntity } from '../models/units.models';
import { UnitActivate, UnitActivated, UnitJoinToUser, UnitJointToUser, UnitRegister, UnitRegistered } from '../interfaces/units.interfaces';
import { SubscriptionKind } from '../types/socketio.types';
import { WebsocketEventName } from '../models/events-names.models';
import { WebsocketMessage } from '../models/websocket.models';
import { Language } from '../types/users.types';

@Injectable()
export class UnitsService {
    //Centrale editata
    public editedUnitObserver: ReplaySubject<UnitEntity>;
    private editedUnitEntity: UnitEntity;

    //Centrali listate
    public unitsByUserObserver: ReplaySubject<UnitByUserEntity[]>;
    private unitsByUser: UnitByUserEntity[];

    //Centrali searchate
    public searchedUnitsObserver: ReplaySubject<UnitEntity[]>;
    private searchedUnitsEntities: UnitEntity[];

    //Conteggio Centrali
    public countUnitsObserver: ReplaySubject<number>;
    private countUnits: number;

    //Centrale indoor
    public indoorUnitObserver: ReplaySubject<UnitByUserEntity>;
    private indoorUnitEntity: UnitByUserEntity;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private navCtrl: NavController, private socketioService: SocketioService, private backendService: BackendService) {
        this.searchedUnitsObserver = new ReplaySubject<UnitEntity[]>(1);
        this.unitsByUserObserver = new ReplaySubject<UnitByUserEntity[]>(1);
        this.editedUnitObserver = new ReplaySubject<UnitEntity>(1);
        this.countUnitsObserver = new ReplaySubject<number>(1);
        this.indoorUnitObserver = new ReplaySubject<UnitByUserEntity>(1);

        //Ci mettiamo in ascolto dei messaggi websocket unit
        this.socketioService.getMessage(WebsocketEventName.updateUnit).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onUpdateUnit(kafkaMessage.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi websocket unit by user
        this.socketioService.getMessage(WebsocketEventName.insertUnitsByUser).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onInsertUnitByUser(kafkaMessage.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi websocket unit by user
        this.socketioService.getMessage(WebsocketEventName.updateUnitsByUser).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onUpdateUnitByUser(kafkaMessage.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi websocket unit by user
        this.socketioService.getMessage(WebsocketEventName.deleteUnitsByUser).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onDeleteUnitByUser(kafkaMessage.payload);
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onUpdateUnit(payload: UnitByUserEntity) {
        //Ne aggiorniamo l'oggetto originale
        this.indoorUnitEntity = { ...this.indoorUnitEntity, ...payload };
        //Notifichiamo i nuovi dati della unit
        // //console.log('Emitting new indoorUnitEntity...', this.indoorUnitEntity);
        this.indoorUnitObserver.next(this.indoorUnitEntity);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onInsertUnitByUser(payload: UnitByUserEntity) {
        //Cerchiamo nella lista di units by user
        const index = this.unitsByUser?.findIndex((unit) => unit.unitId == payload.unitId);
        //Se il websocket porta un device che non conosciamo...
        if (index === -1) {
            //Aggiorniamo la lista di unit
            this.unitsByUser.push(payload);
            //Aggiungiamo la nuova unit
            // //console.log('Emitting new indoorUnitEntity...', this.unitsByUser);
            this.unitsByUserObserver.next(this.unitsByUser);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onUpdateUnitByUser(payload: UnitByUserData) {
        //Aggiorniamo il singolo device
        if (this.indoorUnitEntity?.unitId === payload.unitId) {
            //Ne aggiorniamo l'oggetto originale
            this.indoorUnitEntity = { ...this.indoorUnitEntity, ...payload };
            //Notifichiamo i nuovi dati della unit
            // //console.log('Emitting new indoorUnitEntity...', this.indoorUnitEntity);
            this.indoorUnitObserver.next(this.indoorUnitEntity);
        }

        //Cerchiamo nella la lista di dellle centrali by user
        const index = this.unitsByUser?.findIndex((unit) => unit.unitId == payload.unitId);
        //Se il websocket porta una unit che già conosciamo...
        if (index) {
            if (index !== -1) {
                //Ne aggiorniamo l'oggetto originale
                this.unitsByUser[index] = { ...this.unitsByUser[index], ...payload };
                //Notifichiamo i nuovi dati della unit
                //   //console.log('Emitting new indoorUnitEntity...', this.unitsByUser);
                this.unitsByUserObserver.next(this.unitsByUser);
            }
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onDeleteUnitByUser(payload: UnitByUserData) {
        //Cerchiamo nella lista di dellle centrali by user
        const index = this.unitsByUser?.findIndex((unit) => unit.unitId == payload.unitId);
        //Se il websocket porta una unit che già conosciamo...
        if (index !== -1) {
            //Lo cancelliamo dalla lista
            this.unitsByUser.splice(index, 1);
            //Notifichiamo i nuovi dati della unit
            //    //console.log('Emitting new indoorUnitEntity...', this.unitsByUser);
            this.unitsByUserObserver.next(this.unitsByUser);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Lista le centrali outdoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitsList(fetchSize: number, pageState?: string): Observable<UnitEntity[]> {
        return this.backendService.get<UnitEntity[]>(`/v2/units`, { fetchSize, pageState }).pipe(
            map((units: UnitEntity[] | any) => {
                if (!pageState) {
                    //Aggiorniamo le info della lista impianti
                    this.searchedUnitsEntities = units.result;
                    //Notifichiamo i nuovi dati evento
                    console.log('Emitting virgin searchedUnitsEntities list...', this.searchedUnitsEntities);
                    this.searchedUnitsObserver.next(this.searchedUnitsEntities);
                } else {
                    for (let i = 0; i < units.result.length; i++) {
                        this.searchedUnitsEntities.push(units.result[i]);
                    }
                    //Notifichiamo i nuovi dati evento
                    console.log('Emitting added searchedUnitsEntities list...', this.searchedUnitsEntities);
                    this.searchedUnitsObserver.next(this.searchedUnitsEntities);
                }
                return units;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitsCount(): Observable<number> {
        return this.backendService.get(`/v2/units/count`, {}).pipe(
            map((count: number) => {
                //Aggiorniamo il conteggio degli impianti
                this.countUnits = count;
                //Notifichiamo i nuovi dati impianto
                this.countUnitsObserver.next(this.countUnits);
                return count;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitsScout(): Observable<number> {
        return this.backendService.post(`/v2/units/scout`, {}).pipe(
            map((count: number) => {
                //Ritorniamo il numero delle unit modificate
                return count;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitsFakeFill(quantity: number): Observable<number> {
        return this.backendService.post(`/v2/units/fake/fill`, { quantity }).pipe(
            map((count: number) => {
                //Ritorniamo il numero delle unit create
                return count;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitsFakePurge(): Observable<number> {
        return this.backendService.delete(`/v2/units/fake/cleanup`, {}).pipe(
            map((count: number) => {
                //Ritorniamo il numero delle unit cancellate
                return count;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public changeSerialNumber(unitId, newSerialNumber): Observable<number> {
        return this.backendService.post(`/v2/units/change-serial-number`, { unitId, newSerialNumber }).pipe(
            map((count: number) => {
                //Ritorniamo il numero delle unit cancellate
                return count;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitRead(unitId: string): Observable<UnitEntity> {
        return this.backendService.get<UnitEntity>(`/v2/units/read`, { unitId }).pipe(
            map((unit: UnitEntity) => {
                //Aggiorniamo le info dell'impianto editato
                this.editedUnitEntity = unit;
                //Notifichiamo i nuovi dati impianto
                //console.log('Emitting new editedUnitEntity...', this.editedUnitEntity);
                this.editedUnitObserver.next(this.editedUnitEntity);
                return unit;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Ricerca centrali outdoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitsSearch(match: string, limit = 10): Observable<UnitEntity[]> {
        return this.backendService.get<UnitEntity[]>(`/v2/units/search`, { match, limit }).pipe(
            map((units: UnitEntity[]) => {
                //Aggiorniamo le info della lista impianti
                this.searchedUnitsEntities = units;
                //Notifichiamo i nuovi dati impianto
                //console.log('Emitting new searchedUnitsEntities...', this.searchedUnitsEntities);
                this.searchedUnitsObserver.next(this.searchedUnitsEntities);
                return units;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Listiamo tutte le centrali di quel particolare utente
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitsByUserList(userId: string): Observable<UnitByUserEntity[]> {
        return this.backendService.get<UnitByUserEntity[]>(`/v2/units/indoor`, { userId }).pipe(
            map((units: UnitByUserEntity[]) => {
                //Aggiorniamo le info della lista impianti
                this.unitsByUser = units;
                //Notifichiamo i nuovi dati impianto
                //console.log('Emitting new listedUnitsEntities...', this.unitsByUser);
                this.unitsByUserObserver.next(this.unitsByUser);
                return units;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Leggiamo i dati della centrale indoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadIndoorUnitEntity(unitId: string, userId: string): Observable<UnitByUserEntity> {
        //Leggiamo i dati della centrale in cui siamo
        return this.backendService.get<UnitByUserEntity>(`/v2/units/indoor/read`, { unitId, userId }).pipe(
            map((unit: UnitByUserEntity) => {
                //Aggiorniamo le info della centrale in cui siamo
                this.indoorUnitEntity = unit;
                //TODO Fare dopo
                // //Ci iscriviamo al suo topic per avere gli aggiornamenti in tempo reale
                this.socketioService.topicSubscribe(unit.unitId, SubscriptionKind.unit, unit.unitName);

                //Notifichiamo i nuovi dati utente
                // //console.log('Emitting new indoorUnitEntity...', this.indoorUnitEntity);
                this.indoorUnitObserver.next(this.indoorUnitEntity);
                return unit;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Chiamata da homeOut()
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public clearIndoorUnitEntity() {
        //Se siamo in una centrale indoor...
        if (this.indoorUnitEntity) {
            //Ci disiscriviamo dal suo topic
            this.socketioService.topicUnsubscribe(this.indoorUnitEntity.unitId);
        }
        this.indoorUnitEntity = {} as UnitByUserEntity;
        this.indoorUnitObserver.next(this.indoorUnitEntity);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitUploadImage(formData: FormData): Observable<void> {
        return this.backendService.post<void>(`/v2/units/image/upload`, formData, {
            options: { headers: {} }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitDownloadImage(unitId: string): Observable<Buffer> {
        if (unitId !== undefined) {
            return this.backendService.get<Buffer>(`/v2/units/image/download`, { unitId });
        } else {
            console.error('unitId is undefined');
            return null;
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitDeleteImage(unitId: string): Observable<Buffer> {
        return this.backendService.delete<Buffer>(`/v2/units/image/delete`, { unitId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Activate unit
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitActivate(data: UnitActivate): Observable<UnitActivated> {
        return this.backendService.post<UnitActivated>(`/v2/units/activate`, data);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Register unit
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitRegister(data: UnitRegister): Observable<UnitRegistered> {
        return this.backendService.post<UnitRegistered>(`/v2/units/register`, data);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Join unit
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitJoinToSerialNumber(data: UnitJoinToUser): Observable<UnitEntity> {
        return this.backendService.post<UnitEntity>(`/v2/units/join`, data);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Link unit (Add unit to an user)
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitLinkToUser(unitId: string, userId: string): Observable<UnitEntity> {
        return this.backendService.post<UnitEntity>(`/v2/units/link`, {
            unitId,
            userId
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Link unit (Add unit to an user) for maintenance, the link will be undone after 24 hours
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitLinkToUserForMaintenance(unitId: string, userId: string, apiKey: string): Observable<UnitEntity> {
        return this.backendService.post<UnitEntity>(
            `/v2/units/link-maintenance`,
            {
                unitId,
                userId
            },
            { options: { headers: { 'api-key': apiKey } } }
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Cambiamo l'immagine di sfondo della centrale
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public imageUpload(formData: FormData): Observable<void> {
        return this.backendService.post<void>(`/v2/units/image/upload`, formData, {
            options: { headers: {} }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitUpdate(unitId: string, data: UnitData): Observable<UnitEntity> {
        return this.backendService.put(`/v2/units/update`, { unitId, ...data }).pipe(
            map((unit: UnitEntity) => {
                //console.log('updated unit', unit);
                //Aggiorniamo le info della unit
                //ATTENZIONE NON VA BENE!!!
                this.indoorUnitEntity = unit as UnitByUserEntity;
                //TODO Fare dopo
                // //Ci iscriviamo al suo topic per avere gli aggiornamenti in tempo reale
                // this.websocket.topicSubscribe(user_id);
                //Notifichiamo i nuovi dati unit
                // //console.log('Emitting new indoorUnitEntity...', this.indoorUnitEntity);
                this.indoorUnitObserver.next(this.indoorUnitEntity);
                return unit;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitIndoorGet = (unitId: string, userId: string, check?: boolean): Observable<UnitByUserEntity> =>
        this.backendService.get<UnitByUserEntity>(`/v2/units`, { unitId, userId }, { options: { params: { check: JSON.stringify(check) } } });

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitOutdoorGet = (): Observable<UnitEntity[]> => this.backendService.get<UnitEntity[]>(`/v2/units`);

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitIndoorUnlink(unitId: string, userId: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/units/unlink`, { unitId, userId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitDelete(unitId: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/units/delete`, { unitId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public unitFormat(unitId: string): Observable<void> {
        return this.backendService.post<void>(`/v2/units/format`, { unitId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public dockerComposeLoad = (unitId: string): Observable<HttpResponse<Blob>> =>
        this.backendService.get<HttpResponse<Blob>>(
            `/v2/units/docker-compose`,
            { unitId },
            {
                options: {
                    responseType: 'blob',
                    observe: 'response'
                }
            }
        );

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public dockerComposeUpload(formData: FormData): Observable<number> {
        return this.backendService.put<number>(`/v2/units/docker-compose/upload`, formData, {
            options: { headers: {} }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Invio mail di autorizzazione all utente
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public maintenanceAllowMail(
        unitId: string,
        unitName: string,
        userId: string,
        email: string,
        firstName: string,
        supporterUserId: string,
        sender,
        redirect,
        language
    ): Observable<void> {
        return this.backendService.post<void>(`/v2/units/maintenance`, {
            unitId,
            unitName,
            userId,
            email,
            firstName,
            sender,
            redirect,
            language,
            supporterUserId
        });
    }
}
