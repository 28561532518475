import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'app-item-input-checkbox',
    templateUrl: './item-input-checkbox.component.html',
    styleUrls: ['./item-input-checkbox.component.scss']
})
export class ItemInputCheckboxComponent implements OnInit {
    form: FormGroup;
    @Input() labelPosition: string = 'floating';
    @Input() lines = 'full';
    @Input() label: string;
    @Input() controlName: string;
    @Input() contentClass: string;
    @Input() theme: AppTheme;
    @Input() formGroupName: string;
    @Input() showPolicies$: boolean = true;
    @Input() checked$: boolean = false;
    private required: boolean;

    constructor(private router: Router, private rootFormGroup: FormGroupDirective) {}

    ngOnInit() {
        this.form = this.formGroupName ? (this.rootFormGroup.control.get(this.formGroupName) as FormGroup) : this.rootFormGroup.control;

        this.required =
            this.form.controls[this.controlName].validator && this.form.controls[this.controlName].validator({} as AbstractControl)?.required ? true : false;
    }
    showPolicies = (): void => {
        this.router.navigateByUrl(`/welcome/policies-it`);
    };
}
