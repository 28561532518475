<ion-grid class="ion-no-margin ion-no-padding">
    <ion-row class="ion-no-margin ion-no-padding">
        <ion-col [size]="colsize" *ngFor="let daysTimeWindow of daysTimeWindows; index as i">
            <!-- Card finestra temporale member -->
            <ion-card class="card-time-window">
                <ion-grid class="ion-no-margin ion-no-padding">
                    <ion-row class="ion-justify-content-between ion-align-items-center">
                        <ion-col size="3" class="ion-text-start ion-align-items-center padding-bottom-8">
                            <ion-badge class="ion-no-padding" [class]="colorBadge" mode="ios">
                                <svg-icon
                                    style="padding: 6px"
                                    [src]="'assets/ha/svg/' + scheduleIcon + '.svg'"
                                    [svgStyle]="{ 'height.px': 14, fill: '#c5c5c5' }"
                                ></svg-icon>
                            </ion-badge>
                            <ion-text *ngIf="daysTimeWindow.name" class="padding-left-4 text-input-roboto-bold-16" [ngClass]="'white-' + theme">
                                {{ daysTimeWindow.name }}
                            </ion-text>
                        </ion-col>
                        <ion-col size="3" class="ion-text-end ion-no-margin ion-no-padding" *ngIf="editableCard">
                            <ion-icon
                                type="button"
                                size="small"
                                [color]="theme"
                                class="pointer"
                                name="ellipsis-vertical"
                                (click)="presentPopover($event, i)"
                            ></ion-icon>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-no-margin ion-no-padding">
                        <ion-col size="12">
                            <ion-label class="pointer" style="margin: 16px 0">
                                <ng-container *ngFor="let isDaySelected of daysTimeWindow.days; index as index">
                                    <ion-text *ngIf="index == 0" [class]="classDays">{{ 'label.title-days' | translate }}:</ion-text>
                                    <ion-text [class]="classDays" *ngIf="isDaySelected" style="color: #f15a24">{{ days[index].key | translate }},</ion-text>
                                </ng-container>
                                <br />
                                <ion-text [class]="classDays" *ngIf="daysTimeWindow.allday">{{ 'label.title-end-start' | translate }}:</ion-text>
                                <ion-text [class]="classDays" style="color: #f15a24" *ngIf="daysTimeWindow.allday">
                                    {{ 'user.daysTimeWindows.allDay' | translate }}
                                </ion-text>
                            </ion-label>
                            <ion-label *ngIf="!daysTimeWindow.allday">
                                <ion-text [class]="classDays">{{ 'label.title-end-start' | translate }}:</ion-text>

                                <ion-text *ngIf="fromTrigger" [class]="classDays" style="color: #f15a24">{{ daysTimeWindow.start }}</ion-text>
                                <ion-text *ngIf="!fromTrigger" [class]="classDays" style="color: #f15a24">
                                    {{ daysTimeWindow.start }} - {{ daysTimeWindow.end }}
                                </ion-text>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-card>
        </ion-col>
    </ion-row>
</ion-grid>
