/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    Configurazione da far girare per lo sviluppo in test (su VPS Aruba).
    Si compila con il comando yarn build:test
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/

import pack from '../../package.json';

// The list of file replacements can be found in `angular.json`.
export const environment = {
    appVersion: `test.${pack.version}`,
    name: 'test',
    fakeUsers: false,
    fakeUnits: false,
    skipTwoFA: true,
    skipCaptcha: true,
    frontendUrl: 'https://app.lilitech.cloud',
    backendUrl: 'https://api.lilitech.cloud',
    i18nPrefix: '/assets/i18n/',
    recaptcha: {
        siteKey: '6LfnoNEaAAAAAIcD2mk533alBlVsegwXVQMF51-l'
    },
    map: {
        mapCenter: [7.7107478424773825, 45.098946719040924]
    },
    weather_Api_Key: '1590160abe5eb493be17f0dc046d4da5'
};
