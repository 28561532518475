import { AppTheme, DaysTimeWindow, FavoriteHand, Group, Language, MeasurementSystem, Role, SignUp, TimeFormat, UserDisplayMode } from '../types/users.types';

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export class UserEntity {
    avatar: string;
    userId: string;
    group: Group;
    email: string;
    firstName: string;
    lastName: string;
    enable: boolean;
    ban: boolean;
    theme: AppTheme;
    language: Language;

    displayMode: UserDisplayMode;
    social: string;
    speechName: string;
    measurementSystem: MeasurementSystem;
    color: string;
    phoneNumber: string;
    telegramChatId: string;
    favoriteHand: FavoriteHand;
    timeFormat: TimeFormat;
    logRetention: number;
    logLevels: number[];
    signUp: SignUp;
    loginRemember: boolean;
    policy: boolean;
    stripeCustomerId: string;
    scout: string;
    fake: boolean;
    lastHomeIn: string;
}

export type UserData = Omit<Partial<UserEntity>, 'userId'>;

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export class UserByUnitEntity extends UserEntity {
    unitId: string;
    enable: boolean;
    email: string;
    role: Role;
    viewId: string;
    viewName: string;
    timeWindowsEnable: boolean;
    daysTimeWindows: Array<DaysTimeWindow>;
    checkIn: string;
    checkOut: string;
    logLevels: number[];
    ttl: number;
    favouritesHint: boolean;
}

export type UserByUnitData = Omit<Partial<UserByUnitEntity>, 'userId'>;

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export interface UserPendingEntity {
    apiKey: string;
    unitId: string;
    inviterName: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    image: string;
    language: Language;
    role: Role;
    willExpireAt: Date;
    timeWindowsEnable: boolean;
    daysTimeWindows: Array<DaysTimeWindow>;
    checkIn: string;
    checkOut: string;
    viewId: string;
    viewName: string;
}

export type UserPendingData = Omit<Partial<UserPendingEntity>, 'apiKey'>;

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    Interfaccia usata per ricevede i dati per l'invito dell'utente
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export type UserInviteData = {
    unitId: string;
    inviterName?: string;
    invitedName: string;
    invitedLastName: string;
    email: string;
    enable?: boolean;
    language: Language;
    role: Role;
    viewId: string;
    viewName: string;
    timeWindowsEnable?: boolean;
    daysTimeWindows?: Array<DaysTimeWindow>;
    checkIn?: string;
    checkOut?: string;
    redirect?: string;
};

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    Interfaccia e tipo usati per ritornare l'esito dell'invito dell'utente
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export enum InviteStatus {
    invited = 'invited',
    linked = 'linked',
    alreadyLinked = 'alreadyLinked'
}
export type UserInviteResult = {
    status: InviteStatus;
};

export interface UsertMaintenanceAuthEntity {
    supportedId: string;
    supporterId: string;
    supporterEmail: string;
    maintenanceAuth: boolean;
}
