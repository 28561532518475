import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { UserEntity } from 'src/app/models/users.models';
import { UserByWelcomeEntity } from 'src/app/models/welcome.models';
import { UsersService } from 'src/app/services/users.service';
import { WelcomeService } from 'src/app/services/welcome.service';
import { AppTheme, DaysTimeWindow, Language, Role } from 'src/app/types/users.types';

@Component({
    selector: 'app-outdoor-pending-user-edit-modal',
    templateUrl: './outdoor-pending-user-edit-modal.component.html',
    styleUrls: ['./outdoor-pending-user-edit-modal.component.scss']
})
export class OutdoorPendingUserEditModalComponent implements OnInit {
    @Input() user: UserByWelcomeEntity;
    @Input() theme: AppTheme;
    @Input() reinviterName: string;
    public titleWindowsEnable = 'user.timeWindowsEnable';
    public languageSelected: Language = 'it-IT' as Language;
    public form: FormGroup;
    public roleSelected: Role;
    public timeWindowsEnable: boolean;
    public daysTimeWindows: Array<DaysTimeWindow> = [];
    public checkIn: string;
    public checkOut: string;

    constructor(public modalController: ModalController, private usersService: UsersService, private welcomeService: WelcomeService) {}

    ngOnInit() {
        this.form = new FormGroup({
            role: new FormControl(this.user.role, { validators: Validators.required, updateOn: 'submit' }),
            viewId: new FormControl(this.user.viewId, { validators: Validators.required, updateOn: 'submit' }),
            viewName: new FormControl(this.user.viewName)
        });

        this.roleSelected = this.user.role;
        this.languageSelected = this.user.language;
        this.checkIn = this.user?.checkIn;
        this.checkOut = this.user?.checkOut;
        this.daysTimeWindows = this.user?.daysTimeWindows;
        if (this.user.role === 'member') {
            this.timeWindowsEnable = this.user.daysTimeWindows?.length > 0 ?? true;
        } else if (this.user.role === 'guest') {
            if (this.user.checkIn !== null || this.user.checkIn !== undefined) {
                this.timeWindowsEnable = true;
            } else {
                this.timeWindowsEnable = false;
            }
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose() {
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeLanguage(event: any, key: string) {
        this.languageSelected = event.value;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeRole(event: any, key: string) {
        if (event.value !== this.form.controls.role.getRawValue()) {
            this.roleSelected = event.value;
            this.form.controls.role.setValue(event.value);
            this.form.controls.viewId.setValue(null);
            this.form.controls.viewName.setValue(null);
        }
        if (event.value === 'owner' || event.value === 'service') {
            this.daysTimeWindows = null;
            this.timeWindowsEnable = false;
            this.checkIn = null;
            this.checkOut = null;
        }
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeView(event: any, key: string) {
        this.form.controls.viewId.setValue(event.viewId);
        this.form.controls.viewName.setValue(event.name);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    timeWindowsEnableValueChanged(newValue: boolean) {
        this.timeWindowsEnable = newValue;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userPendingUpdate(): void {
        if (this.roleSelected === 'member' || this.roleSelected === 'guest') {
            this.timeWindowsEnable = true;
        } else {
            this.timeWindowsEnable = false;
        }
        const data = {
            role: this.form.value.role,
            viewId: this.form.value.viewId,
            viewName: this.form.value.viewName,
            language: this.languageSelected,
            timeWindowsEnable: this.timeWindowsEnable
        };
        //Se timeWindowsEnable è true controlliamo che tipo di tempo stiamo assegnando (se da giorno x a giorno y o se per singoli giorni)
        if (this.timeWindowsEnable) {
            //Se abbiamo dei giorni dentro daysTimeWindows allora checkIn e checkOut saranno sicuramente null, resettiamo anche sul db
            //Se allDay è true escludiamo start e end
            if (this.daysTimeWindows?.length > 0 && this.roleSelected === 'member') {
                this.daysTimeWindows.forEach((day) => {
                    if (day.allday) {
                        day.start = day.end = null;
                    }
                });
                data['daysTimeWindows'] = this.daysTimeWindows;
                data['checkIn'] = null;
                data['checkOut'] = null;
            }
            //Se abbiamo checkIn e checkOut sicuramente daysTimeWindows deve essere vuoto, resettiamo anche sul db
            else if (this.checkIn && this.checkOut && this.roleSelected === 'guest') {
                data['daysTimeWindows'] = null;
                data['checkIn'] = this.checkIn;
                data['checkOut'] = this.checkOut;
                //Se il guest o il member non hanno inidicazioni temporali, resettiamo anche sul db
            } else {
                data['daysTimeWindows'] = null;
                data['checkIn'] = null;
                data['checkOut'] = null;
            }
        }
        //Se timeWindowsEnable è false resettiamo ogni riferimento al tempo
        else {
            data['daysTimeWindows'] = null;
            data['checkIn'] = null;
            data['checkOut'] = null;
        }

        this.usersService.updatePendingUser(this.user.apiKey, this.reinviterName, data).subscribe({
            next: async () => {
                await firstValueFrom(this.welcomeService.usersList());
            },
            error: (error: HttpErrorResponse) => {
                alert(error.message);
            }
        });
        this.modalController.dismiss();
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    removeTimezoneOffset(isoString: string) {
        if (isoString.length >= 20) return isoString.substring(0, 19);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    daysValueChanged(data: DaysTimeWindow[]) {
        this.daysTimeWindows = data;
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy(): void {
        this.daysTimeWindows = this.checkIn = this.checkOut = this.timeWindowsEnable = null;
    }
}
