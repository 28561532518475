<ion-item [detail]="button" lines="full" class="ion-no-margin ion-no-padding" mode="ios" [disabled]="!button" [button]="button">
    <ion-label class="ion-no-margin ion-no-padding">
        <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="'medium-'+theme">{{ label | translate }}</ion-text>
        <ion-input
            class="text-input-roboto-regular-16 ion-no-margin ion-no-padding"
            [value]="measureUnit? (value | translate) + ' ' + measureUnit : (value | translate)"
            [color]="theme"
            readonly="true"
            [placeholder]="placeHolder"
        ></ion-input>
    </ion-label>
</ion-item>
