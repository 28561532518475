import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoleChooserModalComponent } from './role-chooser-modal/role-chooser-modal.component';
import { AppTheme, Role } from 'src/app/types/users.types';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import { RoleEntity, RoleService } from 'src/app/services/role.service';
import { PlatformService } from 'src/app/services/platform.service';
import { log } from 'console';

@Component({
    selector: 'role-chooser',
    templateUrl: './role-chooser.page.html',
    styleUrls: ['./role-chooser.page.scss']
})
export class RoleChooserPage implements OnInit {
    public showValue: string;
    public roles: RoleEntity[] = [];

    @Input() theme: AppTheme;
    @Input() set value(value: string) {
        const format = this.roles.find((f) => f.value === value);
        this.showValue = format.key;
    }
    @Input() detail = true;
    @Input() inviterRole: string;
    @Input() set userRole(value: string) {
        if (value === 'owner') {
            this.inviterRole = value;
            this.roles = this.roles ? this.roles.filter((role) => role.value === 'member' || role.value === 'guest') : this.roles;
        } else if (value === Role.guest) {
            this.roles = this.roles ? this.roles.filter((role) => role.value === 'guest') : this.roles;
        } else if (value === Role.member) {
            this.roles = this.roles ? this.roles.filter((role) => role.value === 'member') : this.roles;
        }
    }
    @Output() selected = new EventEmitter<string>();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private platformService: PlatformService, public modalController: ModalController, private roleService: RoleService) {
        this.roles = this.roleService.roles;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    chooseRole = async (): Promise<void> => {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: RoleChooserModalComponent,
            componentProps: {
                theme: this.theme,
                roles: this.roles,
                showValue: this.showValue,
                inviterRole: this.inviterRole
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();

        const res = await modal.onWillDismiss();

        if (res.data) {
            this.showValue = res.data.key;
            this.selected.emit(res.data);
        }
    };
}
