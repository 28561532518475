import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild, ElementRef } from '@angular/core';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'searchbar-card',
    templateUrl: './searchbar-card.page.html',
    styleUrls: ['./searchbar-card.page.scss']
})
export class SearchbarCardPage implements OnDestroy {
    @Input() theme: AppTheme;
    @Input() searchLabel: string = 'generic-search.label';
    @Input() debounce = 150;
    @Input() size = 12;
    @Input() adaptWidth: string;
    @Input() addMarginPadding: string = '';
    @Input() value: string = '';

    @Input() setFocus: boolean = true;
    @Output() searchTermChange = new EventEmitter<string>();
    @Output() clearSearchFilter = new EventEmitter<boolean>();

    @ViewChild('ionSearchbar', { read: ElementRef }) ionSearchbar: ElementRef;
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngAfterViewInit() {
        if (this.setFocus) {
            setTimeout(() => {
                const searchbar = this.ionSearchbar.nativeElement as HTMLIonSearchbarElement;
                searchbar.setFocus();
            }, 150);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/

    onChangeSearchValue(event: Event): void {
        const {
            detail: { value }
        } = event as CustomEvent;
        this.searchTermChange.emit(value);
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    clearSearchLabel(): void {
        this.searchLabel = null;
        this.clearSearchFilter.emit(true);
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy() {}
}
