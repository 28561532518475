<item-section-header [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()" [title]="'users.pending.edit-modal'"></item-section-header>
<!-- <section-header-background
    [theme]="theme"
    [imageDataUrl]="user.image"
    [name]="user.firstName"
    [backgroundTransparent]="true"
    imageDataUrl="assets/backgrounds/user-default.webp"
></section-header-background> -->
<ion-content [ngClass]="contentClass">
    <!-- <ion-text class="h3-roboto-medium-18">{{ 'users.info.edit-modal.description' | translate | titlecase }}</ion-text> -->

    <ion-list lines="full">
        <item-label-readout label="user.email" [value]="user.email"></item-label-readout>
        <form [formGroup]="form">
            <!--<language-chooser [theme]="theme" [value]="user.language" (selected)="changeLanguage($event, 'language')"></language-chooser>-->
            <role-chooser [theme]="theme" [value]="user.role" (selected)="changeRole($event, 'role')"></role-chooser>
            <view-chooser
                [theme]="theme"
                [unitId]="user.unitId"
                (selected)="changeView($event, 'view')"
                [viewName]="viewName"
                [role]="roleSelected"
                [isEdit]="true"
            ></view-chooser>

            <access-configuration
                *ngIf="roleSelected === 'member' || roleSelected === 'guest'"
                [timeWindowsEnable]="timeWindowsEnable"
                [role]="roleSelected"
                [theme]="theme"
                [(daysTimeWindows)]="daysTimeWindows"
                [(checkIn)]="checkIn"
                [(checkOut)]="checkOut"
                (valueChange)="timeWindowsEnableValueChanged($event)"
                (daysTimeWindowsChange)="daysValueChanged($event)"
                [titleWindowsEnable]="titleWindowsEnable"
            ></access-configuration>

            <ion-item lines="full" detail="false" class="ion-no-padding ion-no-margin">
                <ion-label>{{ 'user.label.unlink' | translate }}</ion-label>
                <ion-button [color]="theme" slot="end" (click)="userPendingRevoke()" class="outline">{{ 'button.revoke' | translate }}</ion-button>
            </ion-item>
            <ion-item lines="full" detail="false" class="ion-no-padding ion-no-margin">
                <ion-label>{{ 'user.label.resend.invite' | translate }}</ion-label>
                <ion-button [color]="theme" slot="end" (click)="userPendingReinvite()" class="outline">{{ 'button.re-invite' | translate }}</ion-button>
            </ion-item>
        </form>
        <ion-button (click)="userPendingUpdate()" class="solid button-modal" expand="block" [disabled]="!form.valid">
            {{ 'button.submit' | translate }}
        </ion-button>
    </ion-list>
</ion-content>
