<ion-item [detail]="true" lines="inset" class="ion-no-padding ion-no-margin" (click)="userEdit()">
    <ion-thumbnail slot="start">
        <ion-img *ngIf="avatar" alt="" [src]="avatar"></ion-img>
    </ion-thumbnail>
    <ion-label>
        <ion-text [class]="className">{{ user.firstName }} {{ user?.lastName }}</ion-text>
        <ion-text class="role-label roboto-regular-10">{{ user.role | uppercase }}</ion-text>
        <ion-text [class]="classEmail" class="email" [ngClass]="'medium-' + theme$">{{ user.email }}</ion-text>
    </ion-label>
</ion-item>
