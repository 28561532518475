<ion-item detail="false" [lines]="lines" class="ion-no-margin ion-no-padding" mode="ios" *ngIf="theme">
    <ion-label class="ion-no-margin ion-no-padding">
        <ion-text class="ion-no-margin ion-no-padding text-input-roboto-regular-16 display-flex ion-align-items-center">
            {{ title | translate }}
            <ion-badge *ngIf="iconBadge" [class]="badgeColorEconomy" class="margin-left-4 d-inline-flex align-items-center">
                <svg-icon [src]="'assets/ha/svg/' + iconBadge + '.svg'" [style.fill]="'white'" [svgStyle]="{ 'height.px': 10, 'width.px': 10 }"></svg-icon>
            </ion-badge>
        </ion-text>

        <ion-text *ngIf="!descriptionButton" [ngClass]="labelClass" class="label-item-roboto-regular-13 ion-no-margin ion-no-padding wrap-text">
            {{ description | translate }}
        </ion-text>
        <ion-text
            *ngIf="descriptionButton && !excludeForever"
            [ngClass]="labelClass"
            class="label-item-roboto-regular-13 ion-no-margin ion-no-padding wrap-text"
        >
            {{ 'device.exclusion.at' | translate }} : {{ descriptionValue | date: 'd/M/yy, h:mm a' }}
        </ion-text>
        <ion-text
            *ngIf="descriptionButton && excludeForever"
            [ngClass]="labelClass"
            class="label-item-roboto-regular-13 ion-no-margin ion-no-padding wrap-text"
        >
            {{ 'device.exclusion.forever' | translate }}
        </ion-text>
    </ion-label>
    <ion-toggle
        *ngIf="enableToggle"
        class="color"
        [checked]="checked"
        (ionChange)="itemValueChange($event)"
        slot="end"
        [disabled]="isSwitchDisabled"
    ></ion-toggle>
</ion-item>
