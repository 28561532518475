<ion-content [ngClass]="contentClass">
    <item-section-header [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()" [title]="'user.sections.time-window'"></item-section-header>
    <form [formGroup]="scheduleForm">
        <item-input-text [label]="label" controlName="tariffName" [theme]="theme" (onInputChange)="onTariffNameChange($event)"></item-input-text>
    </form>
    <date-time-window-chooser
        [theme]="theme"
        [daysTimeWindow]="daysTime"
        [fromTrigger]="fromTrigger"
        ($cancel)="modalClose()"
        ($submitRequest)="submitRequest($event)"
    ></date-time-window-chooser>
</ion-content>
