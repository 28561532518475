import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';
import { BackendService } from '../backend.service';
import { TechnologyIndoorEntity, TechnologyEntity, TechnologyCount } from '../../models/technologies/technologies.models';
import { TecnologiesIndoorAdd, TecnologiesIndoorRemove } from '../../interfaces/technologies.interfaces';
import { SocketioService } from '../socketio.service';
import { WebsocketMessage } from 'src/app/models/websocket.models';
import { WebsocketEventName } from 'src/app/models/events-names.models';

@Injectable({
    providedIn: 'root'
})
export class TechnologiesService {
    //Tecnologie disponibili
    public availableTechnologiesObserver: ReplaySubject<TechnologyEntity[]>;
    private availableTechnologiesEntities: TechnologyEntity[];
    //Tecnologie abilitate
    public enabledTechnologiesObserver: ReplaySubject<TechnologyEntity[]>;
    private enabledTechnologiesEntities: TechnologyEntity[];
    //Tecnologie presenti indoor
    public indoorTechnologiesObserver: ReplaySubject<TechnologyIndoorEntity[]>;
    private indoorTechnologiesEntities: TechnologyIndoorEntity[];

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private backendService: BackendService, private socketioService: SocketioService) {
        this.availableTechnologiesObserver = new ReplaySubject<TechnologyEntity[]>(1);
        this.enabledTechnologiesObserver = new ReplaySubject<TechnologyEntity[]>(1);
        this.indoorTechnologiesObserver = new ReplaySubject<TechnologyIndoorEntity[]>(1);

        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.insertTechnologiesByUnit).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onInsertTechnology(kafkaMessage.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.updateTechnologiesByUnit).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onUpdateTechnology(kafkaMessage.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.deleteTechnologiesByUnit).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onDeleteTechnology(kafkaMessage.payload);
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onInsertTechnology(payload: TechnologyIndoorEntity) {
        //Cerchiamo nella la lista di tecnologie
        const index = this.indoorTechnologiesEntities?.findIndex((technology) => technology.technologyId == payload.technologyId);
        //Se il messaggio websocket porta una tecnologia che già conosciamo...
        if (index !== -1) {
            //Ne aggiorniamo l'oggetto originale
            this.indoorTechnologiesEntities[index] = { ...this.indoorTechnologiesEntities[index], ...payload };
            //Notifichiamo i nuovi dati della tecnologia
            console.log('Emitting updated indoor technologies list...', this.indoorTechnologiesEntities);
            this.indoorTechnologiesObserver.next(this.indoorTechnologiesEntities);
        } else {
            //Aggiorniamo la lista delle tecnologie
            this.indoorTechnologiesEntities.push(payload);
            //Notifichiamo i nuovi dati della tecnologia
            console.log('Emitting updated indoor technologies list...', this.indoorTechnologiesEntities);
            this.indoorTechnologiesObserver.next(this.indoorTechnologiesEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onUpdateTechnology(payload: TechnologyIndoorEntity) {
        //Cerchiamo nella la lista di tecnologie
        const index = this.indoorTechnologiesEntities?.findIndex((technology) => technology.technologyId == payload.technologyId);
        //Se il messaggio websocket porta una tecnologia che già conosciamo...
        if (index !== -1) {
            //Ne aggiorniamo l'oggetto originale
            this.indoorTechnologiesEntities[index] = { ...this.indoorTechnologiesEntities[index], ...payload };
            //Notifichiamo i nuovi dati della tecnologia
            console.log('Emitting updated indoor technologies list...', this.indoorTechnologiesEntities);
            this.indoorTechnologiesObserver.next(this.indoorTechnologiesEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onDeleteTechnology(payload: TechnologyIndoorEntity) {
        //Cerchiamo nella la lista di tecnologie
        const index = this.indoorTechnologiesEntities?.findIndex((technology) => technology.technologyId == payload.technologyId);
        //Se il messaggio websocket porta una tecnologia che conosciamo...
        if (index !== -1) {
            //Lo cancelliamo dalla lista
            this.indoorTechnologiesEntities.splice(index, 1);
            //Notifichiamo la nuova lista di tecnologie
            console.log('Emitting updated indoor technologies list...', this.indoorTechnologiesEntities);
            this.indoorTechnologiesObserver.next(this.indoorTechnologiesEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo la lista delle tecnologie Lilitech
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadAvailableTechnologies(): Observable<TechnologyEntity[]> {
        //Leggiamo i dati delle tecnnologie Lilitech
        return this.backendService.get<TechnologyEntity[]>(`/v2/technologies/available`, {}).pipe(
            map((technologies: TechnologyEntity[]) => {
                //Aggiorniamo le info delle tecnologie
                this.availableTechnologiesEntities = technologies;
                //Notifichiamo l'elenco delle tecnologie
                console.log('Emitting new available technologies list...', this.availableTechnologiesEntities);
                this.availableTechnologiesObserver.next(this.availableTechnologiesEntities);
                return technologies;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo la lista delle tecnologie abilitate Lilitech
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadEnabledTechnologies(): Observable<TechnologyEntity[]> {
        //Leggiamo i dati delle tecnologie abilitate Lilitech
        return this.backendService.get<TechnologyEntity[]>(`/v2/technologies/enabled`, {}).pipe(
            map((technologies: TechnologyEntity[]) => {
                //Aggiorniamo le info delle tecnologie abilitate
                this.enabledTechnologiesEntities = technologies;
                //Notifichiamo l'elenco delle tecnologie abilitate
                console.log('Emitting new enabled technologies list...', this.enabledTechnologiesEntities);
                this.enabledTechnologiesObserver.next(this.enabledTechnologiesEntities);
                return technologies;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo la lista delle tecnologie indoor
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadIndoorTechnologies(unitId: string): Observable<TechnologyIndoorEntity[]> {
        //Leggiamo i dati delle tecnologie abilitate Lilitech
        return this.backendService.get<TechnologyIndoorEntity[]>(`/v2/technologies/indoor`, { unitId }).pipe(
            map((technologies: TechnologyIndoorEntity[]) => {
                //Aggiorniamo le info delle tecnologie abilitate
                this.indoorTechnologiesEntities = technologies;
                //Notifichiamo l'elenco delle tecnologie abilitate
                console.log('Emitting new indoor technologies list...', this.indoorTechnologiesEntities);
                this.indoorTechnologiesObserver.next(this.indoorTechnologiesEntities);
                return technologies;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiorniamo una tecnologia
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public updateTechnology(technologyId: string, data: TechnologyEntity): Observable<TechnologyEntity> {
        return this.backendService.put<TechnologyEntity>(`/v2/technologies/update`, { technologyId, ...data });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiungiamo una tecnologia
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public addInddorTechnology(unitId: string, technology: string): Observable<void> {
        return this.backendService.post(`/v2/technologies/indoor/add`, { unitId, technology });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Rimuoviamo una tecnologia
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public delIndoorTechnology(unitId: string, technologyId: string): Observable<void> {
        return this.backendService.post(`/v2/technologies/indoor/remove`, { unitId, technologyId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo le count delle varie tecnologie
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public countTechnologies(): Observable<TechnologyCount> {
        //Leggiamo i dati delle tecnologie abilitate Lilitech
        return this.backendService.get<TechnologyCount>(`/v2/technologies/outdoor/count`).pipe(
            map((technologiesCount: TechnologyCount) => {
                return technologiesCount;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Resettiamo gli array del service
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public resetObservableArray(){
        this.availableTechnologiesEntities = [];
        this.enabledTechnologiesEntities = [];
        this.indoorTechnologiesEntities = [];

        this.availableTechnologiesObserver?.next(this.availableTechnologiesEntities);
        this.enabledTechnologiesObserver?.next(this.enabledTechnologiesEntities);
        this.indoorTechnologiesObserver?.next(this.indoorTechnologiesEntities);
    }
}
