import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { UserPendingEntity } from 'src/app/models/users.models';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'pending-user-item',
    templateUrl: './pending-user-item.component.html',
    styleUrls: ['./pending-user-item.component.scss']
})
export class PendingUserItemComponent {
    @Input() set theme(tema: AppTheme) {
        if (tema) {
            this.theme$ = tema;
            this.setStyle();
        }
    }
    public theme$: AppTheme;
    @Input() user: UserPendingEntity;
    public className: string;
    public classEmail: string;
    public avatar: string;

    @Output() userEdit$ = new EventEmitter();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private platformService: PlatformService, public alertController: AlertController) {
        this.setStyle();
        this.platformService.resize.subscribe(async () => this.setStyle());
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {
        if (this.user.image) {
            const blob: Blob = new Blob([new Uint8Array(<Buffer>this.user.image['data'])], { type: 'image/jpeg' });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => (this.avatar = <string>reader.result);
        } else {
            this.avatar = 'assets/backgrounds/user-default.webp';
        }
    }
    ngAfterViewInit(): void {
        this.setStyle();
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            this.className = 'text-input-roboto-regular-16';
            this.classEmail = 'label-item-roboto-regular-13 display-block';
        }
        if (this.platformService.isSmartphone()) {
            (this.className = 'text-input-roboto-regular-16 '), (this.classEmail = 'label-item-roboto-regular-13 display-block ');
        }
        if (this.platformService.isTablet()) {
            (this.className = 'text-input-roboto-regular-16'), (this.classEmail = 'label-item-roboto-regular-13 display-block ');
        }
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userEdit = (): void => this.userEdit$.emit();
}
