import { ChangeDetectorRef, Component, Optional } from '@angular/core';
import { NotificationChannel } from '../models/notifications/notifications.model';
import { AppTheme } from '../types/users.types';
import { DevicesFamilyNames, DevicesFamilyTags, FamiliesAvatars } from '../types/devices-families.types';
import { ModalController, ToastController } from '@ionic/angular';
import namedColors from 'color-name-list';
import nearestColor from 'nearest-color';
import { AutomationTypesNames } from '../types/devices/automations.types';
@Component({
    selector: 'utilities',
    templateUrl: './utilities.component.html',
    styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent {
    public display: string = 'week';
    public daysSelected: (boolean | null)[] = new Array(7).fill(false);
    public theme: AppTheme;
    public activeButtonColor: string = 'null';
    public cdr: ChangeDetectorRef;
    public selectedAvatar: string;
    public selectedTags: string[];

    private colors = {
        red: '#FF0000',
        green: '#00FF00',
        springGreen: '#00FF7F',
        lightGreen: '#66FF00',
        pastelGreen: '#77DD77',
        greenMint: '#98FF98',
        blue: '#0000FF',
        yellow: '#FFFF00',
        safron: '#F4C430',
        white: '#FFFFFF',
        fuchsia: '#F400A1',
        indigo: '#4B0082',
        octane: '#00665C',
        pink: '#FFC0CB',
        turquoise: '#30D5C8',
        violet: '#800080',
        greenForest: '#228B22',
        lime: '#CCFF00',
        lemon: '#FDE910',
        lightBlue: '#99CBFF',
        denim: '#1560BD',
        lavender: '#E6E6FA',
        plum: '#660066',
        aubergine: '#991199',
        lightPurple: '#9F00FF',
        orchid: '#DA70D6',
        wine: '#C0007F',
        orange: '#FF6600',
        lilitech: '#F15A24'
    };
    constructor(@Optional() public toastController?: ToastController, @Optional() public modalController?: ModalController) {
        //  if (colorFeedback) this.colorName = this.getColorNameFromRGB(colorFeedback as string);
    }

    get channels(): typeof NotificationChannel {
        return NotificationChannel;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    millisecondsToTime = (
        milliseconds: number
    ): {
        hours: string;
        minutes: string;
        seconds: string;
    } => {
        const seconds = milliseconds / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;

        const hours24 = Math.floor(hours) % 24;
        const minutes60 = Math.floor(minutes) % 60;
        const seconds60 = Math.floor(seconds) % 60;

        return {
            hours: hours24 < 10 ? '0' + hours24 : hours24.toString(),
            minutes: minutes60 < 10 ? '0' + minutes60 : minutes60.toString(),
            seconds: seconds60 < 10 ? '0' + seconds60 : seconds60.toString()
        };
    };

    formatMillisecondsToDays(milliseconds: number) {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        const formattedMinutes = minutes % 60;
        const formattedHours = hours % 24;

        return {
            days: days < 10 ? '0' + days : days.toString(),
            hours: formattedHours < 10 ? '0' + formattedHours : formattedHours.toString(),
            minutes: formattedMinutes < 10 ? '0' + formattedMinutes : formattedMinutes.toString()
        };
    }

    public secondsToTime(seconds: number) {
        // Calcola il numero di minuti
        const minutes = seconds / 60;
        // Calcola il numero di ore
        const hours = minutes / 60;
        // Calcola il numero di giorni
        const days = hours / 24;

        // Estrae i giorni, le ore e i minuti rimanenti
        const daysRemaining = Math.floor(days) % 30;
        const hoursRemaining = Math.floor(hours) % 24;
        const minutesRemaining = Math.floor(minutes) % 60;

        // Formatta i risultati aggiungendo uno zero iniziale se sono inferiori a 10
        const formattedDays = daysRemaining < 10 ? '0' + daysRemaining : daysRemaining.toString();
        const formattedHours = hoursRemaining < 10 ? '0' + hoursRemaining : hoursRemaining.toString();
        const formattedMinutes = minutesRemaining < 10 ? '0' + minutesRemaining : minutesRemaining.toString();

        // Restituisce l'oggetto con giorni, ore e minuti formattati
        return {
            days: formattedDays,
            hours: formattedHours,
            minutes: formattedMinutes
        };
    }

    getCombinedClass(index: number): any {
        return {
            [`${
                this.daysSelected[index]
                    ? 'selectDay button-text-roboto-medium-upper-16 selectDay-' + this.theme
                    : ' button-text-roboto-medium-upper-16 unselectDay-' + this.theme
            }-${this.theme}`]: true
        };
    }

    getClassBasedOnFeedback(feedback: boolean): any {
        return {
            [`solid-${this.theme}`]: feedback,
            [`transparent-${this.theme}`]: !feedback
        };
    }

    getClassBasedOnstringFeedback(feedback: string, button: string): any {
        let classes = {};

        if (button === this.activeButtonColor) {
            classes[`solid-${this.theme}`] = true;
        } else {
            classes[`transparent`] = true;
            switch (feedback) {
                case 'down':
                    classes[`solid-${this.theme}`] = true;
                    break;
                case 'up':
                    classes[`solid-${this.theme}`] = true;
                    break;
                case 'open':
                    classes[`solid-${this.theme}`] = false;
                    break;
                case 'close':
                    classes[`transparent`] = true;
                    break;
                case 'opening':
                    classes[`solid-${this.theme}`] = true;
                    break;
                case 'closing':
                    classes[`solid-${this.theme}`] = true;
                    break;
                case 'idle':
                    classes[`transparent`] = true;
                    break;
                case null:
                    classes[`transparent`] = true;
                    break;
                default:
                    classes[`transparent`] = true;
                    break;
            }
        }
        return classes;
    }

    getClassBasedOnControlsFeedback(feedback: string, buttonType: string): any {
        let classes = {};

        // Verifica se il feedback corrisponde al pulsante corrente
        if (feedback === buttonType) {
            classes[`controls-solid-${this.theme}`] = true; // Colore attivo
        } else {
            classes[`controls-grey-${this.theme}`] = true; // Colore inattivo
        }

        return classes;
    }

    selectedTagsFunc(name: string) {
        switch (name) {
            case DevicesFamilyNames.actuators:
                this.selectedTags = DevicesFamilyTags.actuators;
                this.selectedAvatar = FamiliesAvatars.actuators;
                break;
            case DevicesFamilyNames.automations:
                this.selectedTags = DevicesFamilyTags.automations;
                this.selectedAvatar = FamiliesAvatars.automations;
                break;
            case DevicesFamilyNames.envSensors:
                this.selectedTags = DevicesFamilyTags.envSensors;
                this.selectedAvatar = FamiliesAvatars.envSensors;
                break;
            case DevicesFamilyNames.lights:
                this.selectedTags = DevicesFamilyTags.lights;
                this.selectedAvatar = FamiliesAvatars.lights;
                break;
            case DevicesFamilyNames.meterSensors:
                this.selectedTags = DevicesFamilyTags.meterSensors;
                this.selectedAvatar = FamiliesAvatars.meterSensors;
                break;
            case DevicesFamilyNames.notifiers:
                this.selectedTags = DevicesFamilyTags.notifiers;
                this.selectedAvatar = FamiliesAvatars.notifiers;
                break;
            case DevicesFamilyNames.securityAreas:
                this.selectedTags = DevicesFamilyTags.securityAreas;
                this.selectedAvatar = FamiliesAvatars.securities;
                break;
            case DevicesFamilyNames.securitySensors:
                this.selectedTags = DevicesFamilyTags.securitySensors;
                this.selectedAvatar = FamiliesAvatars.securities;
                break;
            case DevicesFamilyNames.sockets:
                this.selectedTags = DevicesFamilyTags.sockets;
                this.selectedAvatar = FamiliesAvatars.sockets;
                break;
            case DevicesFamilyNames.thermostats:
                this.selectedTags = DevicesFamilyTags.thermostats;
                this.selectedAvatar = FamiliesAvatars.thermostats;
                break;
            case DevicesFamilyNames.systems:
                this.selectedTags = DevicesFamilyTags.systems;
                this.selectedAvatar = FamiliesAvatars.systems;
                break;
            default:
                console.error('Tags non trovati');
                break;
        }
    }
    async modalClose(): Promise<void> {
        await this.modalController.dismiss();
    }
    async toastMessage(message: string, duration?: number, position?: 'top' | 'bottom' | 'middle', icon?: string, color?: string): Promise<void> {
        // Mostriamo un messaggio di attenzione se il valore non è valido
        const toast = await this.toastController.create({
            header: 'Attenzione',
            message: message,
            duration: duration,
            position: position,
            icon: icon,
            color: color,
            buttons: [
                {
                    icon: 'close',
                    side: 'end',
                    role: 'cancel',
                    handler: () => {}
                }
            ]
        });

        toast.present();
    }
    //funzione per cambiare il segment button
    changeSegmentPage(value: string): void {
        this.display = value;

        this.cdr.detectChanges();
    }

    // traduzione per nome del colore

    componentToHex(c: number): string {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }
    rgbToHex(rgb: string): string {
        const parsedRGB = JSON.parse(rgb);
        const r = parsedRGB.r;
        const g = parsedRGB.g;
        const b = parsedRGB.b;
        return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
    getColorNameFromRGB(rgb: string): string {
        console.log(rgb);

        const colorHex = this.rgbToHex(rgb);
        const color = namedColors.find((c) => c.hex === colorHex);
        if (color?.name === undefined || color === undefined) {
            const nc = nearestColor.from(this.colors);
            const nearest = nc(colorHex);
            return nearest ? nearest.name : 'Colore non trovato';
        } else {
            return color ? color.name : 'Colore non trovato';
        }
    }
    /**
     * Questa funzione verifica se l’oggetto passato contenga
     * le proprietà r, g, b di tipo number.
     */
    public isRGBObject(value: any): boolean {
        return (
            value &&
            typeof value === 'object' &&
            'r' in value &&
            typeof value.r === 'number' &&
            'g' in value &&
            typeof value.g === 'number' &&
            'b' in value &&
            typeof value.b === 'number'
        );
    }

    getAutomationIcons(typeId: string): { open: string; stop: string; close: string } {
        switch (typeId) {
            case AutomationTypesNames.shutter:
            case AutomationTypesNames.sunshadeAwning:
                return { open: 'chevron-up-outline', stop: 'pause-outline', close: 'chevron-down-outline' };

            case AutomationTypesNames.sunBlind:
                return { open: 'chevron-down-outline', stop: 'pause-outline', close: 'chevron-up-outline' };

            case AutomationTypesNames.curtain:
                return { open: 'code-outline', stop: 'reorder-two-outline', close: 'arrows-close' };

            case AutomationTypesNames.gate:
                return { open: 'gate-open', stop: 'pause-outline', close: 'gate-close' };

            case AutomationTypesNames.window:
                return { open: 'sliding-window-open', stop: 'pause-outline', close: 'sliding-window-close' };

            case AutomationTypesNames.barrier:
                return { open: 'bar-open', stop: 'pause-outline', close: 'bar-close' };

            case AutomationTypesNames.tilting:
                return { open: 'tilting-open', stop: 'pause-outline', close: 'tilting-closed' };

            case AutomationTypesNames.frontDoor:
                return { open: 'door-open', stop: 'pause-outline', close: 'door-close' };

            case AutomationTypesNames.pedestrianGate:
                return { open: 'pedestrian-gate-open', stop: 'pause-outline', close: 'pedestrian-gate-close' };

            default:
                return { open: 'help-circle-outline', stop: 'help-circle-outline', close: 'help-circle-outline' }; // Default icons
        }
    }
}
